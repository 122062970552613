
import { RequestPaging } from "@/utils/util";
import { Component, Vue } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import LsPagination from "@/components/ls-pagination.vue";
import PopoverInput from "@/components/popover-input.vue";
import { apiModelCategoryList, apiModelCategoryDelete } from "@/api/model.ts";
@Component({
  components: {
    LsDialog,
    LsPagination,
    PopoverInput,
  },
})
export default class ModelCategory extends Vue {
  $refs!: { plTreeTable: any };
  pager = new RequestPaging();
  categoryList: any = [];

  apiModelCategoryList = apiModelCategoryList;

  handleDelete(id: number) {
    apiModelCategoryDelete({ id: id }).then((res) => {
      this.getList();
    });
  }

  getList(): void {
    apiModelCategoryList({}).then((res) => {
      this.categoryList = res.lists;
    });
  }

  created() {
    this.getList();
  }
}
